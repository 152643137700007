export const shouldUpdateScroll = ({ routerProps }) => {
  if (!routerProps.location?.state) return true;
  const { disableScrollUpdate } = routerProps.location.state;

  return !disableScrollUpdate;
};

export const onRouteUpdate = (loc) => {
  const { state } = loc.location;

  if (state && state.refocusId) {
    const elem = document.getElementById(state.refocusId);

    if (elem) {
      elem.focus();
    }
  }
};
